@media only scteen and (max-width:300px){
    .heroContainer h1 {
        font-size: 55px !important;
        font-weight: 600;
        text-align: start;
        line-height:55px !important;
        padding:0px 0px 20px 0px 
      }
}

@media only screen and (max-width: 600px) {
    .secondaryBtn{
        margin:20px 0px;
        border:1px solid #433EA4;
        color:#433EA4;
        padding:16px 32px !important;
        border-radius:64px;
        width:90%;
        font-size:21px

    }
    .heroContainer{
        padding:139px 0px 0px 0px;
        text-align: start
      }
      .heroContainer h1 {
        font-size: 55px;
        font-weight: 600;
        text-align: start;
        line-height:55px;
        padding:0px 0px 20px 0px 
      }
      .heroDescription{
        font-size:18px;
        font-weight: 300 !important;
        color:#424242;
        max-width:327px
      }

      .learnMoreButton{
        background:#433EA4;
        color:white;
        padding:16px 32px !important;
        border-radius:64px;
        margin-right:15px;
        width:90%;
        font-size:21px
    }

      /* .bannerImage{   
        width:100%;
        height:803px !important;
        padding-top:60px;
        object-fit: cover;
    } */

    .bannerImage img{
      width: 100%;
      height: 500px !important;
      object-fit: cover;
      margin:50px 0px;
      padding:60px auto;
      width: 100%;
      z-index: 0;
    }
}

@media only screen and (min-width: 600px) {
   .secondaryBtn{
    border:1px solid #433EA4;
    color:#433EA4;
    padding:16px 29px;
    border-radius:64px;
   }
    .heroContainer{
        padding:139px 0px 0px 0px;
        text-align: center;
      }
      .heroContainer h1 {
        font-size: 64px;
        font-weight: 600;
        text-align: center;
        line-height:64px;
        padding:0px 0px 20px 0px 
      }
      .heroDescription{
        font-size:17px;
        color:#424242
      }

      .bannerImage{
        display:flex;
        justify-content: center;
        margin:auto;
        width:90%;
        padding:60px 0px
    }
}

@media only screen and (min-width: 992px) {
    .heroContainer{
        padding:139px 0px;
        text-align: center;
      }
      .heroContainer h1 {
        font-size: 64px;
        font-weight: 600;
        text-align: center;
        line-height:64px;
        padding:0px 0px 20px 0px 
      }
      .heroDescription{
        font-size:18px;
        width:500px;
        margin:auto
      }
      .bannerImage{
        display:flex;
        justify-content: center;
        margin:auto;
        width:90%;
        padding:60px 0px
    }
}

@media only screen and (min-width: 1200px) {
  .heroContainer{
    padding:139px 0px;
    text-align: center;
    background-image: url("../../images/Group\ 99.png");
  }
  .heroContainer h1 {
    font-size: 64px;
    font-weight: 600;
    text-align: center;
    line-height:64px;
    padding:0px 0px 20px 0px 
  }
  .heroDescription{
    font-size:18px
  }
  .bannerImage{
    display:flex;
    justify-content: center;
    margin:auto;
    width:100%;
    padding:60px 0px
}
}


.learnMoreButton{
    background:#433EA4;
    color:white;
    padding:16px 29px;
    border-radius:64px
}

.heroAction{
    padding:64px 0px 0px 0px
}

