@media only screen and (max-width:600px){
    .mobileBankingTitle{
        font-size:55px !important;
        font-weight:600;
        line-height:55px !important;
        margin:20px 0px;
    }
    .mobileBankingContainer{
        margin:100px 0px
    }

    .scheduleButton{
        color:#5752BD;
        font-weight:500
    }
}


@media only screen and (min-width:600px){
    .mobileBankingTitle{
        font-size:64px;
        font-weight:600;
        line-height:64px;
        margin:20px 0px
    }
    .mobileBankingContainer{
        margin:100px 0px;
        display:flex;
        justify-content: space-between;
    }

    .mobileBankingContainer p{
        max-width: 525px;
    }

    .scheduleButton{
        color:#5752BD;
        font-weight:500
    }

    .contentSection{
        padding:150px 0px 0px 0px
    }
}