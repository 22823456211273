@media only screen and (max-width:600px){
    .finedgeTitle{
        font-size:55px;
        font-weight:800;
        line-height:55px;
        margin:90px auto auto auto;
        text-align:start
    }

    .finedgeDescription{
        max-width:500px;
        font-size:19px;
        margin:40px auto;
        text-align:start;
        font-weight: 100;
    }
}


@media only screen and (min-width:600px){
.finedgeTitle{
    font-size:64px;
    font-weight:600;
    line-height:64px;
    margin:90px auto auto auto
}

.finedgeDescription{
    max-width:500px;
    margin:40px auto
}
}