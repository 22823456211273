@media only screen and (max-width:600px){
    .mobileBankingTitle{
        font-size:40px;
        font-weight:600;
        line-height:40px;
        margin:20px 0px
    }
    .mobileBankingContainer{
        margin:100px 0px
    }

    .scheduleButton{
        color:#5752BD;
        font-weight:500
    }
}


@media only screen and (min-width:600px){
    .mobileBankingTitle{
        font-size:64px;
        font-weight:600;
        line-height:64px;
        margin:20px 0px
    }
    .mobileInternetContainer{
        margin:100px 0px;
        display:flex;
        justify-content: space-between;
        flex-direction: row-reverse;
    }

    .mobileInternetContainer p{
        max-width: 525px;
    }

    .scheduleButton{
        color:#5752BD;
        font-weight:500
    }

    .contentSection{
        padding:150px 0px 0px 0px
    }
}