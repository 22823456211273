@media only screen and (max-width: 600px) {
  .promisesHeader {
    font-weight: 400;
    font-size: 55px;
    line-height: 55px;
  }

  .valuedCustomers {
    color: #5752bd;
  }

  .promiseTitle {
    font-size: 32px;
    width: 190px;
    margin: 0px auto auto 20px;
    font-weight: bold;
  }

  .promiseImage {
    margin: 0px 0px 0px 0px;
  }

  .arrowImage {
    margin: 33px 0px 33px 0px;
  }

  .promiseGrid {
    display: grid;
    grid-template-columns: auto;
  }

  .promiseCard {
    transition: box-shadow 0.1s ease-in;
    margin: 30px 0px;
    display:flex
  }

  .promiseCard:hover {
    box-shadow: 8px 8px 48px rgba(58, 53, 140, 0.13);
    padding: 24px 16px 24px 16px;
    border-radius: 16px;
    /* border-image: linear-gradient(#D9D9D9 0%, #50BFBF 100%, #D9D9D9 33.01%) 45; */
    border-width:0.0013em;
    border-top-left-radius: 16px;
    border-style: solid;
    border-top: 1px solid #50BFBF;
    border-right: 1px solid #50BFBF;
  }
}

@media only screen and (min-width: 600px) {
  .promisesHeader {
    width: 465px;
    font-weight: 400;
    font-size: 48px;
    line-height: 48px;
  }

  .valuedCustomers {
    color: #5752bd;
  }

  .promiseTitle {
    width: 150px;
    font-size: 24px;
    margin: 40px auto auto 20px;
  }

  .promiseImage {
    margin: 0px 0px 0px 0px;
  }

  .promiseGrid {
    display: grid;
    grid-template-columns: auto auto;
  }
}

@media only screen and (min-width: 768px) {
  .promisesHeader {
    width: 465px;
    font-weight: 400;
    font-size: 48px;
    line-height: 48px;
  }

  .valuedCustomers {
    color: #5752bd;
  }

  .promiseTitle {
    width: 150px;
    font-size: 24px;
    margin: 40px auto auto 20px;
  }

  .promiseImage {
    margin: 0px 0px 0px 0px;
  }

  .promiseGrid {
    display: grid;
    grid-template-columns: auto auto auto;
  }
}

@media only screen and (min-width: 996px) {
  .promisesTab{
    display:flex
  }

  .promiseImage{
    margin:40px 40px 31px 32px !important
  }

  .promisesHeader {
    width: 465px;
    font-weight: 400;
    font-size: 48px;
    line-height: 48px;
  }

  .valuedCustomers {
    color: #5752bd;
  }

  .promiseTitle {
    width: 300px !important;
    font-size: 24px;
    margin: 0px auto 40px 32px;
    transition: color 1s ease;
  }

  .promiseTitle:hover {
    color: #51bebe;
  }

  .promiseImage {
    margin: 0px 0px 0px 0px;
  }

  .promiseGrid {
    display: grid;
    grid-template-columns: auto auto auto;
    max-width: 790px;
  }

  .promiseCard{
    box-shadow: 8px 8px 48px rgba(58, 53, 140, 0.13);

    border-radius: 16px;
    border-width:0.0013em;
    border-top-left-radius: 16px;
    border-style: solid;
    border-top: 1px solid #50BFBF;
    border-right: 1px solid #50BFBF;
    margin:20px
  }
}

@media only screen and (min-width: 1200px) {
  .promisesHeader {
    width: 465px;
    font-weight: 400;
    font-size: 48px;
    line-height: 48px;
  }

  .valuedCustomers {
    color: #5752bd;
  }
}

.greyText {
  color: #616161;
}
