@media only screen and (max-width: 600px) {
  .container {
    padding: 40px 20px;
  }
  .mobileMenu {
    display: block;
  }
  .navMenu {
    display: none;
  }

  .imageBanner{
    height:453px;
    object-fit: cover;
  }
}

@media only screen and (min-width: 600px) {
  .container {
    padding: 40px 40px;
  }

  .mobileMenu {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    padding: 40px 40px;
  }

  .mobileMenu {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .container {
    padding: 40px 40px;
  }

  .mobileMenu {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    padding: 40px 80px 0px 80px;
  }

  .mobileMenu {
    display: none;
  }
}

@media only screen and (min-width: 1500px) {
  .container {
    padding: 40px 80px;
    max-width: 1500px;
    margin: auto;
  }

  .mobileMenu {
    display: none;
  }
}

.modalPosition {
  margin: auto !important;
}


@media only screen and (min-width:768px){
.modalContent {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top:100px;
  width:500px
}
}



@media only screen and (max-width:767px){
    .modalContent {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding-top:100px;
      width:300px
    }
    }