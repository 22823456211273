.bankingPackageContainer {
  margin: 50px 0px;
}

.featureText {
  color: #51bebe;
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .bankingContainer{
    display:flex;
    width:200px
    
  }
  .bankingTitle{
    font-size:22px;
    font-weight:600;
    padding:60px 0px 0px 0px;
    width:120px
  }
  .bankingPackageHeader {
    width: auto;
    font-weight: 600;
    font-size:55px;
    line-height: 55px;
    padding: 20px 0px;
  }

  .bankingHeader {
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
  }

  .valuedCustomers {
    color: #5752bd;
  }

  .bankingTitle {
    font-size: 24px;
    margin: 0px auto 40px auto;
    font-weight: medium;
  }

  .bankingImage {
    margin: 40px 0px 0px 0px;
  }

  .bankingGrid {
    display: grid;
    grid-template-columns: auto;
  }
}

@media only screen and (min-width: 600px) {
  .bankingsHeader {
    width: 465px;
    font-weight: 400;
    font-size: 48px;
    line-height: 48px;
  }

  .valuedCustomers {
    color: #5752bd;
  }

  .bankingTitle {
    font-size: 24px;
    margin: 0px auto 40px auto;
    font-weight: medium;
  }

  .bankingImage {
    margin: 40px 0px 0px 0px;
  }

  .bankingGrid {
    display: grid;
    grid-template-columns: auto auto;
  }
}

@media only screen and (min-width: 768px) {
  .bankingsHeader {
    width: 100px;
    font-weight: 400;
    font-size: 64px;
    line-height: 64px;
  }

  .valuedCustomers {
    color: #5752bd;
  }

  .bankingTitle {
    font-size: 24px;
    margin: 0px auto 40px auto;
    font-weight: medium;
  }

  .bankingImage {
    margin: 40px 0px 0px 0px;
  }

  .bankingGrid {
    display: grid;
    grid-template-columns: auto auto;
  }
}

@media only screen and (min-width: 996px) {
    .bankingPackageContainer{
        display:grid;
        grid-template-columns: 1fr 1fr;
        padding:50px 0px
    }
  .bankingPackageHeader {
    width: 300px;
    font-weight: 600;
    font-size: 64px;
    line-height: 64px;
    padding: 20px 0px;
  }

  .bankingsHeader {
    width: 465px;
    font-weight: 400;
    font-size: 64px;
    line-height: 64px;
  }

  .valuedCustomers {
    color: #5752bd;
  }

  .bankingTitle {
    font-size: 24px;
    margin: 0px auto 40px auto;
    font-weight: 500;
  }

  .bankingImage {
    margin: 40px 0px 0px 0px;
  }

  .bankingGrid {
    display: grid;
    grid-template-columns: auto auto;
  }
  .bankingContainer{
    width:140px
  }
}

@media only screen and (min-width: 1200px) {
  .bankingsHeader {
    width: 100px;
    font-weight: 400;
    font-size: 64px;
    line-height: 64px;
  }

  .valuedCustomers {
    color: #5752bd;
  }
}

.greyText {
  color: #616161;
}
