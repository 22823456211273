@media only screen and (max-width:600px){
.finedgeCore{
    padding:142px 0px
}

.finedgeCore h1{
    font-size:55px;
    line-height:55px;
    padding:20px 0px;
    font-weight:900
}

.finedgeGrid{
    margin:35px 0px 0px 0px;
}
.finedgeGrid >div{
    margin:10px 0px
}

.bankIcon {
    width:300px
}

.finedgeParagraph{
    font-size:19px
}

.finedgeGrid >div > span{
    font-size:20px
}
}


@media only screen and (min-width:600px){
    .finedgeCore{
        padding:142px 0px
    }
    
    .finedgeCore h1{
        font-size:64px;
        line-height:64px;
        padding:20px 0px;
        font-weight:600
    }
    .finedgeParagraph{
        max-width:600px
    }

    .finedgeGrid{
        margin:85px 0px 0px 0px;
        display:grid;
        grid-template-columns:auto auto;
        width:400px;
        grid-gap:16px
      
    }
  
    }



    @media only screen and (min-width:768px){
        .finedgeCore{
            padding:142px 0px
        }
        
        .finedgeCore h1{
            font-size:64px;
            line-height:64px;
            padding:20px 0px;
            font-weight:600
        }
        .finedgeParagraph{
            max-width:600px
        }
    
        .finedgeGrid{
            margin:85px 0px 0px 0px;
            display:grid;
            grid-template-columns:auto auto;
            width:400px;
            grid-gap:16px
          
        }

        .bankIcon{
            margin-top:30px
        }
      
        }
    

    

        @media only screen and (min-width:996px){
            .finedgeCore{
                padding:142px 0px
            }
            
            .finedgeCore h1{
                font-size:64px;
                line-height:64px;
                padding:20px 0px;
                font-weight:600
            }
            .finedgeParagraph{
                max-width:600px
            }
        
            .finedgeGrid{
                margin:85px 0px 0px 0px;
                display:grid;
                grid-template-columns:auto auto;
                width:400px;
                grid-gap:16px
              
            }
    
            .bankIcon{
                margin-top:30px 
            }
          
            }


            @media only screen and (min-width:1200px){
                .finedgeCore{
                    padding:142px 0px
                }
                
                .finedgeCore h1{
                    font-size:64px;
                    line-height:64px;
                    padding:20px 0px;
                    font-weight:600
                }
                .finedgeParagraph{
                    max-width:600px
                }
            
                .finedgeGrid{
                    margin:85px 0px 0px 0px;
                    display:grid;
                    grid-template-columns:auto auto;
                    width:400px;
                    grid-gap:16px
                  
                }
        
                .bankIcon{
                    margin-top:30px 
                }
              
                }
        