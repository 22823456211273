@tailwind base;
@tailwind components;
@tailwind utilities;




@font-face{
  font-family: "Clash Display";
  src:url("./fonts/ClashDisplay.ttf")
}

@font-face{
  font-family: "Creato Display";
  src:url("./fonts/CreatoDisplay-Regular.otf")
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Clash Display', sans-serif;
}

h1,h2,h3{
  font-family: 'Clash Display', sans-serif;
}



p , div{
  font-family: 'Creato Display', sans-serif;
}

p{
  font-size:19px
}


code {
    font-family: 'Clash Display', sans-serif;
}
