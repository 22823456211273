@media only screen and (max-width:600px){
    .alternateChannels{
        padding:0px 0px;
    }

    .alternateGrid{
        display:flex;
        overflow-x: scroll;
    }
    
    .alternateGrid > img{
        margin:24px 32px 0px 0px;
    }
}


@media only screen and (min-width:600px){
.alternateChannels{
    padding:0px 0px 0px 0px
}

.alternateGrid > img{
    margin:24px 0px 0px 0px 
}

.alternateGrid {
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:20px
}
}



@media only screen and (min-width:768px){
.alternateGrid {
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:20px
}
}


@media only screen and (min-width:996px){
    .alternateChannels{
        padding:90px 0px 0px 0px
    }
    .alternateGrid {
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap:20px
    }
    }