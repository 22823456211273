.reachOut{
  font-size:30px !important;
  font-weight:600
}

@media only screen and (max-width: 600px) {
  .footerContainer {
    background: url("../../images/footer-background.png");
  }

  .footerHeader {
    width: 255px;
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 55px;
    line-height: 55px;
  }

  .shortLine {
    width: 64px;
    background: #000000;
    border: 0;
    height: 2px;
  }

  .primaryButton {
    display: flex;
    justify-content: space-between;
    padding: 16px 32px;
    gap: 12px;
    width: 80%;
    color: white;
    background: #433ea4;
    border-radius: 64px;
    font-size:21px
  }

  .secondaryButton {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 16px 32px;
    gap: 12px;
    width: 268px;
    border: 1px solid #433ea4;
    border-radius: 64px;
    margin-top: 20px;
    width: 80%;
    font-size:21px
  }
}



@media only screen and (min-width: 600px) {
    .footerContainer {
      background: url("../../images/footer-background.png");
    }
  
    .footerHeader {
      width: 255px;
      font-family: "Clash Display";
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 40px;
    }
  
    .shortLine {
      width: 64px;
      background: #000000;
      border: 0;
      height: 2px;
    }
  
    .primaryButton {
      display: flex;
      justify-content: space-between;
      padding: 20px 48px;
      gap: 12px;
      width: 268px;
      color: white;
      background: #433ea4;
      border-radius: 64px;
    }
  
    .secondaryButton {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      padding: 20px 48px;
      gap: 12px;
      width: 268px;
      border: 1px solid #433ea4;
      border-radius: 64px;
      margin-top: 20px;
    }
  }


  @media only screen and (min-width: 768px) {
    .footerContainer {
      background: url("../../images/footer-background.png");
      display:flex;
      justify-content: space-between;
    }
  
    .footerHeader {
      width: 255px;
      font-family: "Clash Display";
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 40px;
    }

    .footerDescription{
        width:300px;
        font-size:21px
    }
  
    .shortLine {
      width: 64px;
      background: #000000;
      border: 0;
      height: 2px;
    }
  
    .primaryButton {
      display: flex;
      justify-content: space-between;
      padding: 20px 48px;
      gap: 12px;
      width: 268px;
      color: white;
      background: #433ea4;
      border-radius: 64px;
    }
  
    .secondaryButton {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      padding: 20px 48px;
      gap: 12px;
      width: 268px;
      border: 1px solid #433ea4;
      border-radius: 64px;
      margin-top: 20px;
    }
  }
  


  @media only screen and (min-width: 996px) {
    .footerContainer {
      background: url("../../images/footer-background.png");
      background-repeat: repeat-y;
      padding:50px 0px
    }

    .footerFlex{
        display:flex;
        justify-content: space-between;
        width:100%;
        max-width:1200px
    }
  
    .footerHeader {
      width: 255px;
      font-family: "Clash Display";
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 40px;
    }

    .footerDescription{
        width:400px;
        font-size:21px
    }
  
    .shortLine {
      width: 64px;
      border: 0;
      height: 2px;
    }
  
    .primaryButton {
        display: flex;
        justify-content: space-between;
        padding: 20px 48px;
        width: 268px;
        border: 1px solid #433ea4;
        border-radius: 64px;
        margin-top: 20px;
    }
  
    .secondaryButton {
      display: flex;
      justify-content: space-between;
      padding: 20px 48px;
      width: 268px;
      border: 1px solid #433ea4;
      border-radius: 64px;
      margin-top: 20px;
      margin-left:20px
    }

    .footerButtons{
        display:grid;
        grid-template-columns: auto auto;
    }
  }
  