@media only screen and (max-width:600px){
    .productContainer{
        padding:0px 0px 0px 0px;
        text-align:start
    }

    .productContainer .productCoverageTitle{
        font-size:55px;
        font-weight:900;
        line-height: 55px;
        
    }

    .productCoverageDescription{
        text-align:start;
        padding:30px 0px;
        font-size:18px
    }
    
    .tabContainer{
        background:#F3F4F6;
        padding:16px;
        border-radius:56px;
        max-width:465px;
        margin:30px auto;
        overflow-x: scroll;
    }
    
    .tabButton{
        border-radius:40px;
        flex:0 0 auto;
        width:168px;
        padding:10px 8px;
        margin-right:10px;
    }

    .tabButtonActive{
        background:white;
        border-radius:40px;
        flex:0 0 auto;
        width:190px;
        padding:10px 16px;
        margin-right:10px;
        cursor: pointer;
        color:black;
        text-align: center;
    }
    
    .tabButton:active{
        background: white;
        color:black;
        text-align: center;
    }
    
    .tabButton:hover{
        background: white;
        color:black
    }

    .productGridTitle{
        font-size:24px;
        font-weight:600;
        padding:10px 0px
    }

    .gridContainer{
        padding:43px 44px;
        border-radius:50px;
        background:white;
        box-shadow: 8px 8px 88px rgba(58, 53, 140, 0.2);
        border-radius: 0px 24px;
        max-width:456px;
        margin:20px 0px;
        border-top: 1px solid #D900FC;
        border-right: 1px solid #D900FC;
    }

    .gridFeatureContainer{
        display:block;
        max-width:960px;
        margin:auto
    }


}

@media only screen and (min-width:600px){
.productContainer{
    padding:114px 0px 0px 0px;
    text-align:center
}

.productContainer .productCoverageTitle{
    font-size:64px;
    font-weight:600;
    line-height: 64px;
}

.tabContainer{
    padding:16px;
    border-radius:56px;
    max-width:620px;
    margin:30px auto;
   
}

.tabButton{
    border-radius:40px;
    flex:0 0 auto;
    width:190px;
    padding:10px 16px;
    margin-right:10px;
    cursor: pointer;
}

.tabButtonActive{
    background:white;
    border-radius:40px;
    flex:0 0 auto;
    width:190px;
    padding:10px 16px;
    margin-right:10px;
    cursor: pointer;
    color:black !important
}

.tabButton:active{
    background: white;
    color:black
}

.tabButton:hover{
    background: white;
    color:black
}

.productGridTitle{
    font-size:24px;
    font-weight:600;
    padding:10px 0px
}

.gridContainer{
    padding:43px 44px;
    border-radius:50px;
    background:white;
    box-shadow: 8px 8px 88px rgba(58, 53, 140, 0.2);
    border-radius: 0px 24px;
    max-width:456px;
    border-top: 1px solid #D900FC;
    border-right: 1px solid #D900FC;
}

.gridContainer  li{
    color:#6B7280;
    margin:5px auto
}

.gridFeatureContainer{
    display:grid;
    grid-template-columns:1fr 1fr;
    grid-gap:48px;
    max-width:960px;
    margin:auto
}

.productCoverageDescription{
    text-align:start;
    padding:30px 0px;
    font-size:18px;
    max-width:525px;
    margin:auto
}
}






